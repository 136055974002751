export const ADD_INGREDIENT: 'ADD_INGREDIENT' = 'ADD_INGREDIENT';
export const REMOVE_INGREDIENT: 'REMOVE_INGREDIENT' = 'REMOVE_INGREDIENT';
export const CHANGE_BUN: 'CHANGE_BUN' = 'CHANGE_BUN';
export const DRAG_INGREDIENT: 'DRAG_INGREDIENT' = "DRAG_INGREDIENT";
export const CLEAR_CONSTRUCTOR: 'CLEAR_CONSTRUCTOR' = "CLEAR_CONSTRUCTOR";

export const FORGOT_REQUEST: 'PASSWORD_REQUEST' = 'PASSWORD_REQUEST';
export const FORGOT_SUCCESS: 'PASSWORD_SUCCESS' = 'PASSWORD_SUCCESS';
export const FORGOT_FAILED: 'PASSWORD_FAILED' = 'PASSWORD_FAILED';
export const FORGOT_INITIAL: 'SET_FORGOT_INITIAL' = 'SET_FORGOT_INITIAL';

export const GET_INGREDIENTS_FAILED: 'GET_INGREDIENTS_FAILED' = 'GET_INGREDIENTS_FAILED';
export const GET_INGREDIENTS_SUCCESS: 'GET_INGREDIENTS_SUCCESS' = 'GET_INGREDIENTS_SUCCESS';
export const GET_INGREDIENTS_REQUEST: 'GET_INGREDIENTS_REQUEST' = 'GET_INGREDIENTS_REQUEST';
export const SET_INGREDIENT_TAB: 'SET_INGREDIENT_TAB' = 'SET_INGREDIENT_TAB';

export const SET_LOGIN_INFO: "SET_LOGIN_INFO" = "SET_LOGIN_INFO";
export const LOGIN_REQUEST: "LOGIN_REQUEST" = "LOGIN_REQUEST"; 
export const LOGIN_SUCCESS: "LOGIN_SUCCESS" = "LOGIN_SUCCESS"; 
export const LOGIN_FAILED: "LOGIN_FAILED" = "LOGIN_FAILED"; 
export const SET_LOGIN_INITIAL: "SET_LOGIN_INITIAL" = "SET_LOGIN_INITIAL"; 

export const SHOW_MODAL: "SHOW_MODAL" = "SHOW_MODAL";
export const HIDE_MODAL: "HIDE_MODAL" = "HIDE_MODAL";

export const REGISTER_REQUEST: 'REGISTER_REQUEST' = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS: 'REGISTER_SUCCESS' = 'REGISTER_SUCCESS';
export const REGISTER_FAILED: 'REGISTER_FAILED' = 'REGISTER_FAILED';
export const SET_REGISTER_INITIAL: 'SET_REGISTER_INITIAL' = 'SET_REGISTER_INITIAL';

export const RESET_SUCCESS: 'RESET_SUCCESS' = 'RESET_SUCCESS';
export const RESET_REQUEST: 'RESET_REQUIRED' = 'RESET_REQUIRED';
export const RESET_FAILED: 'RESET_FAILED' = 'RESET_FAILED';
export const SET_RESET_INITIAL: 'SET_RESET_INITIAL' = 'SET_RESET_INITIAL';

export const TAKE_ORDER_REQUEST: 'TAKE_ORDER_REQUEST' = 'TAKE_ORDER_REQUEST';
export const TAKE_ORDER_SUCCESS: 'TAKE_ORDER_SUCCESS' = 'TAKE_ORDER_SUCCESS';
export const TAKE_ORDER_FAILED: 'TAKE_ORDER_FAILED' = 'TAKE_ORDER_FAILED';

export const SET_AUTHORIZATION: 'SET_AUTHTORIZATION' = 'SET_AUTHTORIZATION';
export const SET_AUTHORIZATION_INITIAL: 'SET_AUTHTORIZATION_INITIAL' = 'SET_AUTHTORIZATION_INITIAL';
export const SET_AUTHORIZATION_FAILED: 'SET_AUTHTORIZATION_FAILED' = 'SET_AUTHTORIZATION_FAILED';
export const SET_AUTHORIZATION_REQUEST: 'SET_AUTHORIZATION_REQUEST' = 'SET_AUTHORIZATION_REQUEST';

export const WS_CONNECTION_START: 'WS_CONNECTION_START' = 'WS_CONNECTION_START';
export const WS_CONNECTION_SUCCESS: 'WS_CONNECTION_SUCCESS' = 'WS_CONNECTION_SUCCESS';
export const WS_CONNECTION_ERROR: 'WS_CONNECTION_ERROR' = 'WS_CONNECTION_ERROR';
export const WS_CONNECTION_CLOSED: 'WS_CONNECTION_CLOSED' = 'WS_CONNECTION_CLOSED';
export const WS_GET_MESSAGE: 'WS_GET_MESSAGE' = 'WS_GET_MESSAGE';
export const WS_SEND_MESSAGE: 'WS_SEND_MESSAGE' = 'WS_SEND_MESSAGE';

export const WS_ALL_CONNECTION_START: 'WS_ALL_CONNECTION_START' = 'WS_ALL_CONNECTION_START';
export const WS_ALL_CONNECTION_SUCCESS: 'WS_ALL_CONNECTION_SUCCESS' = 'WS_ALL_CONNECTION_SUCCESS';
export const WS_ALL_CONNECTION_ERROR: 'WS_ALL_CONNECTION_ERROR' = 'WS_ALL_CONNECTION_ERROR';
export const WS_ALL_CONNECTION_CLOSED: 'WS_ALL_CONNECTION_CLOSED' = 'WS_ALL_CONNECTION_CLOSED';
export const WS_ALL_GET_MESSAGE: 'WS_ALL_GET_MESSAGE' = 'WS_ALL_GET_MESSAGE';
export const WS_ALL_SEND_MESSAGE: 'WS_ALL_SEND_MESSAGE' = 'WS_ALL_SEND_MESSAGE';

export const GET_ORDER_INFO_SUCCESS: 'GET_ORDER_INFO_SUCCESS' = 'GET_ORDER_INFO_SUCCESS';
export const GET_ORDER_INFO_REQUEST: 'GET_ORDER_INFO_REQUEST' = 'GET_ORDER_INFO_REQUEST';
export const GET_ORDER_INFO_FAILED: 'GET_ORDER_INFO_FAILED' = 'GET_ORDER_INFO_FAILED';